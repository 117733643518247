import L from "leaflet";
import "leaflet-tilelayer-here";
import "../../node_modules/leaflet.locatecontrol/src/L.Control.Locate";

import { gaMark } from "../ga";

window.USE_MAPBOX = true;

export const apiServerBase = () => {
  const URL_LOCAL = "http://localhost:8080";
  const host = window.location.hostname;
  const URL_BASE =
    host.indexOf("localh") !== -1 ? URL_LOCAL : window.location.origin;
  if (host.indexOf("surge") > -1 || host.indexOf("beta") > -1) {
    return "https://nyssetutka.fi";
  }
  return URL_BASE;
};

export const mapObj = () => {
  if (!document.getElementById("map")) {
    const divi = document.createElement("div");
    divi.id = "map";
    document.body.appendChild(divi);
  }
  const mappi = L.map("map", {
    attributionControl: false,
    preferCanvas: true,
  });
  // L.tileLayer(
  //   "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
  //   {
  //     accessToken:
  //       "pk.eyJ1IjoibnR1dGthIiwiYSI6ImNsaXdvejBsZTAwNDIzcHMwd3lkaG1teW8ifQ.nKMA6_YeGaJPPYvIoJKfpQ",
  //     id: "mapbox/streets-v12",
  //     tileSize: 512,
  //     zoomOffset: -1,
  //     maxZoom: 18,
  //     crossOrigin: true,
  //   }
  // ).addTo(mappi);
  L.tileLayer(
    "https://cdn.digitransit.fi/map/v3/hsl-map/{z}/{x}/{y}.png?digitransit-subscription-key=72828c80449a47f19cfa7e9ee6fad503",
    {
      maxZoom: 18,
      crossOrigin: true,
    }
  ).addTo(mappi);
  // L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
  //   maxZoom: 18,
  //   crossOrigin: true
  // }).addTo(mappi);
  // L.tileLayer(
  //   "https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?apiKey=PYXVHEaGvVHAX_ZzhlO7eUz8goylm1-W4lZi2Dm1UII&style=explore.day&size=256",
  //   {
  //     maxZoom: 18,
  //     crossOrigin: true,
  //     key: "5LGfvcVJBOH3aQBdhe6Z_fXKi1J1FQL3q8xZTeOTl2I"
  //   }
  // ).addTo(mappi);
  // L.tileLayer
  //   .here({
  //     apiKey: "1pFA7a8J6A9ipBAMt2sQIUDR8xX0NghgnfnZxOqmIaE",
  //     options: { size: 512 },
  //   })
  //   .addTo(mappi);

  mappi.on("load", () => {
    document.getElementById("start-screen").style.display = "none";
    gaMark("mapLoad");
  });

  mappi.setView([61.4971, 23.778], 15);
  L.control.attribution({ position: "topleft" }).addTo(mappi);

  mappi.createPane("stopPane");

  const stopLayer = L.layerGroup();
  const stopRouteLayer = L.layerGroup();
  const busLayer = L.layerGroup();
  const routeLayer = L.layerGroup();

  window.MAPPI = mappi;
  return {
    mappi,
    stopLayer,
    busLayer,
    stopRouteLayer,
    routeLayer,
  };
};
